import lara from '../../../assets/lara/lara.png'

export function getAvatar() {
    return {
        img: lara,
        alt: "Lara",
        name: "Lara",
        desc: 'Assistente digital',
        header: "Olá, eu sou a Lara",
        subtitle: "Sou a sua <span className='u-fw-600'>assistente digital</span>, com uma voz e um coração humano💙<br/><br/>" +
            "Não se preocupe com chamadas para vendas de produtos. Aqui você <span className='u-fw-600'>simula e vê o seu preço de imediato.</span> Tudo é 100% digital!",
    };
}

export const autoFlow = {
    begin: {
        sequence: [
            {
                result: 'continue',
                type: 'AND',
                conditions: [
                    {
                        comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                    },
                    {
                        comparator: 'NOT_EQUALS',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'restart',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    },
                ],
            },
            {
                result: 'autoType',
            }]
    },
    laraIntroMessage: 'laraIntroMessage',
    pages: {
        continue: {
            type: 'continue',
            nextPageId: 'autoType',
            laraMessage: ['laraAutoContinue', 'laraAutoContinue2'],
            desktopMessage: ['laraAutoContinueDesktop'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblContinueSimulation',

                    },
                    {
                        value: 2,
                        label: 'lblStartNewSimulation',

                    },
                ]
            }
        },
        autoType: {
            startAutoInstance: true,
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'plate',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'autoType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'dontHaveInsuranceType',
                        }]
            },
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraAutoType3', 'laraAutoType2'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'restart',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraAutoType2'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'CURRENT_STEP',
                                    values: [
                                        {
                                            value: "continue",
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraAutoType', 'laraAutoType2'],
                        }]
            },
            desktopMessage: ['laraAutoTypeDesktop'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblAutoType1',

                    },
                    {
                        value: 2,
                        label: 'lblAutoType2',

                    },
                    {
                        value: 3,
                        label: 'lblAutoType3',

                    },
                    {
                        value: 4,
                        label: 'lblAutoType4',

                    },
                ]
            }
        },
        plate: {
            type: 'plate',
            allowBack: false,
            laraMessage: ['laraAutoPlate2'],
            desktopMessage: ['laraAutoPlate2'],
            nextPageId: 'carOwner'
        },
        carOwner: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'carUse',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'usualDriver',
                        }]
            },
            laraMessage: ['laraAutoCarOwner'],
            desktopMessage: ['laraAutoCarOwner'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblAutoCarOwner1',

                    },
                    {
                        value: 2,
                        label: 'lblAutoCarOwner2',

                    },
                    {
                        value: 3,
                        label: 'lblAutoCarOwner3',

                    },
                    {
                        value: 4,
                        label: 'lblAutoCarOwner4',

                    },
                    {
                        value: 5,
                        label: 'lblAutoCarOwner5',

                    },
                ]
            }
        },
        usualDriver: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'notOwnerNoDriver',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: 'carUse',
                        }]
            },
            laraMessage: ['laraUsualDriver', 'laraUsualDriver2', 'laraUsualDriver3'],
            desktopMessage: ['laraUsualDriver'],
            desktopMessageHelper: ['laraUsualDriverHelper', 'laraUsualDriverHelper2'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblUsualDriver1',

                    },
                    {
                        value: 2,
                        label: 'lblUsualDriver2',

                    },
                ]
            }
        },
        carUse: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'noCarUse',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carUse',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'noCarUse',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carUse',
                                        },
                                        {
                                            type: 'constant',
                                            value: 3,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'nif',
                        }]
            },
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraCarUse4'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUse4'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUse3', 'laraCarUse5'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUse0', 'laraCarUse1'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraCarUse4'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUse5'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUseDesktop2'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUseDesktopNoUsualDriver'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraCarUseDesktop'],
                        }]
            },
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblCarUse1',

                    },
                    {
                        value: 2,
                        label: 'lblCarUse2',

                    },
                    {
                        value: 3,
                        label: 'lblCarUse3',

                    },
                ]
            }
        },
        nif: {
            type: 'input',
            startAutoProposal: true,
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraNif6', 'laraNif5'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraNif1', 'laraNif5'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraNif2', 'laraNif5'],
                        }]
            },
            desktopMessageHelper: ['laraNifHelper'],
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraNif6'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraNif1'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraNif2'],
                        }]
            },
            nextPageId: "address",
            options: {
                type: 'number',
                label: 'lblNif',
                subType: 'nif',
            }
        },
        address: {
            type: 'postal',
            updateProposalValue: true,
            hidePostal: true,
            userMessage: 'userAddress',
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraAddress6', 'laraAddress1'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraAddress0', 'laraAddress1'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraAddress2', 'laraAddress1'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraAddressDesktop'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'usualDriver',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraAddressDesktop3'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: ['laraAddressDesktop2'],
                        }]
            },
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'dontHaveInsuranceTypePlate',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'HAS_CAR_INFO',
                                },
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'invalidCategory',
                                        },
                                        {
                                            type: 'constant',
                                            value: true,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'carConfirm',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'HAS_CAR_INFO',
                                },
                            ],
                        },
                        {
                            result: 'carSearch',
                        }]
            },
        },
        carSearch: {
            type: 'loader',
            laraMessage: ['laraCarSearch'],
            desktopMessage: ['laraCarSearch'],
            userMessage: {
                sequence:
                    [{
                        result: null,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EMAIL_USER',
                            }
                        ],
                    },
                    {
                        result: null,
                    }]
            },
            options: {
                barNumber: 1,
                carInfo: true,
                messages: ['A procurar os dados do seu carro', 'Acho que <b>já sei</b>'],
                messageError: ['Não consegui encontrar'],
                interval: 180,
                shorterInterval: 10,
            },
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'dontHaveInsuranceTypePlate',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'HAS_CAR_INFO',
                                },
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'invalidCategory',
                                        },
                                        {
                                            type: 'constant',
                                            value: true,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'carConfirm',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'HAS_CAR_INFO',
                                },
                            ],
                        },
                        {
                            result: 'noCarData',
                        }]
            },
        },
        carConfirm: {
            allowBack: true,//false,
            updateProcessValues: true,
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'carModel',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'needManualModel',
                                        },
                                        {
                                            type: 'constant',
                                            value: true,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'carVersion',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'needManualVersion',
                                        },
                                        {
                                            type: 'constant',
                                            value: true,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'carVersion',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carConfirm',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                                {
                                    comparator: 'CAR_HAS_VERSIONS',

                                }
                            ],
                        },
                        {
                            result: 'carExtras',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carConfirm',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'contactUserNotCar',
                        }]
            },
            laraMessage: {
                sequence:
                    [

                        {
                            result: ['laraCarConfirm3'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraCarConfirm2'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [

                        {
                            result: ['laraCarConfirm3'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraCarConfirm2'],
                        }]
            },
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblCarConfirm1',

                    },
                    {
                        value: 2,
                        label: 'lblCarConfirm2',

                    },
                ]
            }
        },
        carModel: {
            laraMessage: ['laraIKnewIt', 'laraCarModel'],
            desktopMessage: ['laraIKnewIt', 'laraCarModel'],
            nextPageId: 'carVersion',
            type: 'fullRadio',
            options: {
                conditional: 5,
                label: 'lblSelectCarModel',
                list: 'carModelList',
            }

        },
        carVersion: {
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraAutoCarVersion2'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'needManualModel',
                                        },
                                        {
                                            type: 'constant',
                                            value: true,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraAutoCarVersion3'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraAutoCarVersion'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'needManualModel',
                                        },
                                        {
                                            type: 'constant',
                                            value: true,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraAutoCarVersion3'],
                        }]
            },

            nextPageId: {
                sequence: [
                    {
                        result: 'versionConfirm',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'carVersion',
                                    },
                                    {
                                        type: 'constant',
                                        value: 99,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'carNumberOfDoors',
                        type: 'AND',
                        conditions: [

                            {
                                comparator: 'CAR_HAS_VERSIONS_DOORS',

                            },

                        ],
                    },
                    {
                        result: 'carExtras',
                    }]
            },
            type: 'fullRadio',
            options: {
                conditional: 5,
                adjustSize: true,
                label: 'lblSelectCarVersion',
                list: 'carVersionsList',
                otherValues: { versionDoors: 'doors', versionPage: 'page' },
            }

        },
        versionConfirm: {
            nextPageId: {
                sequence: [
                    {
                        result: 'carNumberOfDoors',
                        type: 'AND',
                        conditions: [

                            {
                                comparator: 'CAR_HAS_VERSIONS_DOORS',

                            },

                        ],
                    },
                    {
                        result: 'carExtras',
                    }]
            },
            laraMessage: ['laraVersionConfirm'],
            desktopMessage: ['laraVersionConfirm'],
            type: "next",
            userMessage: 'lblAgree',
            options: {
                label: 'lblAgree'
            }
        },
        carNumberOfDoors: {
            type: 'radio',
            nextPageId: 'carExtras',
            laraMessage: ['laraCarNumberOfDoors'],
            desktopMessage: ['laraCarNumberOfDoors'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblCarNumberOfDoors1',

                    },
                    {
                        value: 2,
                        label: 'lblCarNumberOfDoors2',

                    },
                ]
            }
        },
        carExtras: {
            updateProposalValue: true,
            type: 'radio',
            nextPageId: {
                sequence: [
                    {
                        result: 'noCarExtras',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'reboquePeso',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'hasDanosProprios',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'carOwner',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'bornDate',
                    }]
            },
            userMessage: {
                sequence:
                    [
                        {
                            result: 'userCarExtras',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carExtras',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: 'userCarExtras2',
                        }]
            },
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraIKnewIt', 'laraAutoCarExtras2'], //Corrigir
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'CAR_HAS_NOT_VERSIONS',
                                }
                            ],
                        },
                        {
                            result: ['laraAutoCarExtras2'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraAutoCarExtras'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraAutoCarExtras2'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraAutoCarExtras'],
                        }]
            },
            options: {
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblAutoCarExtras1',
                        followup: [
                            {
                                id: 'reboquePeso',
                                text: 'Qual o peso do reboque?',
                                type: 'radio',
                                items: [
                                    {
                                        value: 1,
                                        label: "menos de 300kg"
                                    },
                                    {
                                        value: 2,
                                        label: "mais de 300kg"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        value: 2,
                        label: 'lblAutoCarExtras2',
                    },
                ]
            }
        },
        contactUserNotCar: {
            type: "contact",
            laraMessage: ['laraContactUserNotCar', 'laraContactUserNotCar2'],
            desktopMessage: ['laraContactUserNotCarDesktop'],
            desktopMessageHelper: ['laraContactUserNotCarDesktopHelper'],
            nextPageId: 'endContactUserNotCar',
            options: {
                cancel: true,
                name: true,
                subject: 'Contactar utilizador',
                contactText: 'Carro mostrado não corresponde ao carro do utilizador',
                cancel: true,
            },

        },
        noCarExtras: {
            laraMessage: ['laraNoCarExtras', 'laraNoCarExtras2'],
            desktopMessage: ['laraNoCarExtrasDesktop'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        notOwnerNoDriver: {
            laraMessage: ['laraNotOwnerNoDriver'],
            desktopMessage: ['laraNotOwnerNoDriver'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        noCarData: {
            laraMessage: ['laraNoCarData'],
            desktopMessage: ['laraNoCarData'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        endContactUserNotCar: {
            laraMessage: ['laraEndContactUserNotCar'],
            desktopMessage: ['laraEndContactUserNotCar'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        noCarUse: {
            laraMessage: {
                sequence:
                    [{
                        result: ['laraNoCarUse1'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'carUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraNoCarUse2'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraNoCarUse1'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'carUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraNoCarUse2'],
                    }]
            },
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        dontHaveInsuranceTypePlate: {
            laraMessage: {
                sequence:
                    [{
                        result: ['laraDontHaveInsuranceType0', 'laraDontHaveInsuranceType'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType20', 'laraDontHaveInsuranceType2'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType30', 'laraDontHaveInsuranceType3'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType4'],
                    }]
            },
            desktopMessageHelper: {
                sequence:
                    [{
                        result: ['laraDontHaveInsuranceType'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType2'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType3'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraDontHaveInsuranceType0'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType20'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType30'],
                    }]
            },
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        dontHaveInsuranceType: {
            laraMessage: {
                sequence:
                    [{
                        result: ['laraDontHaveInsuranceType'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType2'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType3'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType4'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraDontHaveInsuranceType'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType2'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'autoType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDontHaveInsuranceType3'],
                    }]
            },
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        bornDate: {
            type: 'date',
            laraMessage: {
                sequence:
                    [{
                        result: ['bornDate0', 'bornDate2'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'usualDriver',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['bornDate0', 'bornDate1'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['bornDateDesktop2'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'usualDriver',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['bornDateDesktop1'],
                    }]
            },
            nextPageId: 'licenseDate',
            options: {
                onBirthday: "Parabéns! 🎉🍾",
                onAlmostBirthday: "Uau. Está quase a fazer anos 😀",
                validation: {
                    type: "AGE",
                    min: 18,
                    max: 90,
                    minText: "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText: "Não podemos apresentar uma proposta se tiver mais de 90 anos",
                },
                label: 'lblBorndDate',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 30,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 18,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 90,
                            },
                        ]
                    }
                }
            }
        },
        licenseDate: {
            type: 'date',
            updateProposalValue: true,
            allowBack: true,
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraLicenseDate0'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'usualDriver',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraLicenseDate2'],
                    }]
            },
            desktopMessageHelper: ['laraLicenseDate3'],
            laraMessage: {
                sequence:
                    [{
                        result: ['laraLicenseDate0', 'laraLicenseDate3'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'usualDriver',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraLicenseDate2', 'laraLicenseDate3'],
                    }]
            },
            nextPageId: 'hasDanosProprios',
            options: {
                label: 'lblLicenseDate',
                validation: 'notFuture',
                validationMessage: 'Ups. Parece que inseriu uma data no futuro',
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 60,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'PLUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                default: {
                    day: {
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'dayToday',
                            },
                        ]
                    },
                    year: {
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                        ]
                    },
                    month: {
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'monthToday',
                            }
                        ]
                    }
                }
            }
        },
        hasDanosProprios: {
            generateAutoProposal: true,
            type: 'radio',
            nextPageId: 'carGarage',
            laraMessage: ['laraHasDanosProprios0', 'laraHasDanosProprios'],
            desktopMessage: ['laraHasDanosPropriosDesktop'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblHasDanosProprios1',

                    },
                    {
                        value: 2,
                        label: 'lblHasDanosProprios2',

                    },
                    {
                        value: 3,
                        label: 'lblHasDanosProprios3',

                    },
                ]
            }
        },
        carGarage: {
            type: 'radio',
            nextPageId: 'generateProposal',
            laraMessage: ['laraCarGarage'],
            desktopMessage: ['laraCarGarage'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblCarGarage1',

                    },
                    {
                        value: 2,
                        label: 'lblCarGarage2',

                    },
                ]
            }
        },
        generateProposal: {
            allowBack: false,
            type: 'loader',
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraGenerateProposalOther'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraGenerateProposal'],
                        }]
            },
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraGenerateProposalOther'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'NOT_EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraGenerateProposal'],
                        }]
            },
            userMessage: {
                sequence:
                    [{
                        result: null,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EMAIL_USER',
                            }
                        ],
                    },
                    {
                        result: null,
                    }]
            },
            options: {
                barNumber: 5,
                carProposal: true,
                messages: ['A analisar os<br/><b>dados do seu carro</b>',
                    'A analisar a sua<br/><b>experiência de condução</b>',
                    'A analisar a<br/><b>zona de circulação</b>',
                    'A procurar a<br/><b>melhor proposta</b>',
                    'Um pouco de<br/><b>magia uthere</b> 😉',
                    'Já analisei os <b>seus dados</b>'],
                interval: 65,
            },
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'alreadyClient',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'IS_ALREADY_AUTO_CLIENT',
                                },
                            ],
                        },
                        {
                            result: 'showProposal',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'carOwner',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },

                                    ]
                                }
                            ],
                        },
                        {
                            result: 'finalBeforeProposal',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'AUTO_SINISTRO',
                                    values: [
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },

                                    ]
                                },
                                {
                                    comparator: 'AUTO_SINISTRO',
                                    values: [
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },

                                    ]
                                }
                            ],
                        },
                        {
                            result: 'showProposal',
                        }]
            }
        },
        finalBeforeProposal: {
            allowBack: false,
            nextPageId: "showProposal",
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraFinalBeforeProposal2', 'laraFinalBeforeProposal9'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'AUTO_SINISTRO',
                                    values: [
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },

                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraFinalBeforeProposal3', 'laraFinalBeforeProposal9'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraFinalBeforeProposal2', 'laraFinalBeforeProposal9'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'AUTO_SINISTRO',
                                    values: [
                                        {
                                            type: 'constant',
                                            value: 0,
                                        },

                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraFinalBeforeProposal3', 'laraFinalBeforeProposal9'],
                        }]
            },
            type: "next",
            userMessage: 'lblUnderstood',
            options: {
                label: 'lblUnderstood'
            }
        },
        showProposal: {
            allowBack: false,
            type: 'proposal',
            options: {
                subType: 'show',
            },
            nextPageId: "helpCanceling",
            laraMessage: { autoProposalMessage: true },
            desktopMessageHelper: { autoProposalMessage: true },
            desktopMessage: ['laraProposalShow0'],
            userMessage: null,
            userMessageType: 'homeProposal',
            userMessageOptions: {
                valueId: 'selectedProposal',
                type: 'window',
                items: [
                    {
                        id: 'selectedProposal',
                        type: 'selectedProposal',
                        titleLabel: 'Proposta'
                    },
                ]
            }

        },
        alreadyClient: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'contactUserAlreadyClientNo',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'alreadyClient',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'contactUserAlreadyClient',
                        }]
            },
            last: true,
            laraMessage: ['laraAlreadyClient', 'laraAlreadyClient2'],
            desktopMessage: ['laraAlreadyClient'],
            desktopMessageHelper: ['laraAlreadyClient2'],
            options: {
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblAlreadyClient1',
                    },
                    {
                        value: 2,
                        label: 'lblAlreadyClient2',
                    },
                ]
            }
        },
        contactUserAlreadyClient: {
            type: "contact",
            laraMessage: ['laraContactUserAlreadyClient'],
            desktopMessage: ['laraContactUserAlreadyClient'],
            nextPageId: 'endContactUserAlreadyClient',
            options: {
                cancel: true,
                name: true,
                subject: 'Contactar utilizador',
                contactText: 'Já é cliente da Tranquilidade e quer outra proposta'
            },
        },
        contactUserAlreadyClientNo: {
            laraMessage: ['laraContactUserAlreadyClientNo'],
            desktopMessage: ['laraContactUserAlreadyClientNo'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        endContactUserAlreadyClient: {
            laraMessage: ['laraEndContactUserAlreadyClient'],
            desktopMessage: ['laraEndContactUserAlreadyClient'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
            options: {
                cancel: true,
            }
        },
        helpCanceling: {
            allowBack: false,
            allowRestart: false,
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'rateSimulation',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'helpCanceling',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'signCancel',
                        }]
            },
            laraMessage: ['laraHelpCanceling0', 'laraHelpCanceling1'],
            desktopMessage: ['laraHelpCancelingDesktop'],
            desktopMessageHelper: ['desktopMessageHelper'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblHelpCanceling1',

                    },
                    {
                        value: 2,
                        label: 'lblHelpCanceling2',

                    },
                ]
            }
        },
        signCancel: {
            disableExitConfirm: true,
            last: true,
            laraMessage: ['laraSignCancel0', 'laraSignCancel1'],
            desktopMessage: ['laraSignCancel2'],
            type: 'signature',
            nextPageId: 'rateSimulation',
            allowRestart: false,
            userMessage: 'userSignCancel',
            userMessageOptions: {
                type: 'photo',
                url: 'signCancel',
            },
            options: {
                title: { id: "cancelAuto" },
                text: { id: "cancelAuto" },
                label: "Assinar",
            }
        },
        rateSimulation: {
            disableExitConfirm: true,
            last: true,
            laraMessage: ['laraRateSimulation'],
            desktopMessage: ['laraRateSimulation'],
            hideAvatar: true,
            type: 'rate',
            userMessage: 'userRateSimulation',
            nextPageId: 'exitSimulation',
            allowBack: false,
            allowRestart: false,
        },
        exitSimulation: {
            hideAvatar: true,
            laraMessage: ['laraExitSimulation'],
            desktopMessage: ['laraExitSimulation'],
            disableExitConfirm: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
        },
    }
};