import { getCookie, updateCookies, getUthereCookies } from '../logic/cookies/CookiesLogic';
import { generateUid, getRandomInt } from '../utils/GeneralUtils';
import { getDb } from '../firebase/UthereFirebase';
import { doc, collection, increment, setDoc } from "firebase/firestore";
import { getVwoGroup } from './VwoServices';

let loadTime = null;
let userIncrement = null;

let ctaTimeStamp = null;

const mapCookiesSessionVars = {
    utherNewUser: "u_new_user",
    uthereGuestId: "u_guest_user_id",
    uthereSessionId: "u_session_id",
    uthereSessionCount: "u_session_count",
    uthereSessionBegin: "u_begin_session_date",
    uthereAge: "u_age",
    uthereGender: "u_gender",
};
export function updateConsentMode(analytics, marketing) {
    try {
        import("firebase/analytics").then(async analyticsImp => {
            try {
                analyticsImp.setConsent(
                    {
                        'ad_storage': marketing ? 'granted' : 'denied',
                        'ad_personalization': marketing ? 'granted' : 'denied',
                        'ad_user_data': marketing ? 'granted' : 'denied',
                        'analytics_storage': analytics ? 'granted' : 'denied',
                    });
            }
            catch (e) { }
        });
    }
    catch (e) { }
}
export function getLoadTime() {
    return loadTime;
}

export async function incrementVist() {
    try {
        if (!userIncrement) {
            userIncrement = true;
            let today = new Date();
            let dateString = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate();
            let db = await getDb();
            const publicCollectionRef = collection(db, "public");
            await setDoc(doc(publicCollectionRef, dateString), {
                numUsers: increment(1),
            }, { merge: true });
            userIncrement = true;
        }
    }
    catch (e) {
    }
}

export function setLoadedApp(name) {
    if (!loadTime) {
        let sessionValues = {
            u_load_complete: 1,
            u_loaded_page: name,
        }
        loadTime = new Date().getTime() - window._INITIAL_LOADING_TIMESTAMP;
        sessionValues.u_load_time = loadTime;
        setSessionValues(sessionValues, false);
    }
};

export function initBasicAnalytics() {
    return new Promise(async function (resolve, reject) {
        try {
            initUthereCoookies();
            resolve();
        }
        catch (e) {
            resolve();
        }
    });
}

/**
 * getUthereCoookies - obtém o object de cookies atual
 * @returns 
 */
function initUthereCoookies() {
    //Get possible Cookies
    let currentDate = new Date().getTime();
    let uthereGuestIdTemp = getCookie("uthereGuestId");
    let uthereSessionIdTemp = getCookie("uthereSessionId");
    let uthereSessionBeginTemp = getCookie("uthereSessionBegin");
    let uthereSessionEndTemp = getCookie("uthereSessionBegin");
    let uthereSessionCountTemp = getCookie("uthereSessionCount");
    let uthereAgeTemp = getCookie("uthereAge");
    let uthereGenderTemp = getCookie("uthereGender");
    let isNewSession = isNewSessionFunction(uthereSessionIdTemp, uthereSessionEndTemp);

    if (isNewSession) {
        uthereSessionIdTemp = generateUid();
        uthereSessionBeginTemp = currentDate;
        uthereSessionEndTemp = null;
    }

    //Construct cookies object
    let uthereCookies = {
        utherNewUser: !uthereGuestIdTemp ? 1 : 0,
        uthereGuestId: !uthereGuestIdTemp ? generateUid() : uthereGuestIdTemp,
        uthereSessionId: !uthereSessionIdTemp ? generateUid() : uthereSessionIdTemp,
        uthereSessionCount: !isNewSession ? (!uthereSessionCountTemp ? 1 : (parseInt(uthereSessionCountTemp) + 1)) : 1,
        uthereSessionBegin: !uthereSessionBeginTemp ? currentDate : uthereSessionBeginTemp,
        uthereSessionEnd: null,
        uthereAge: !uthereAgeTemp ? "ND" : uthereAgeTemp,
        uthereGender: !uthereGenderTemp ? "U" : uthereGenderTemp,
    }

    setSessionValues(uthereCookies, true);
    updateCookies(uthereCookies);

    //Lança listener para verificar se utilizador voltou/abandonou a tab uthere
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === 'visible') {
            updateUthereSessionReturn();
        }
        else {
            updateUthereSessionLeave();
        }
    });
};

/**
 * Atualiza a sessão quando o utilizaodor deixa a tab
 */
function updateUthereSessionLeave() {
    let currentDate = new Date().getTime();
    let uthereCookies = getUthereCookies()
    uthereCookies.uthereSessionEnd = currentDate;
    updateCookies(uthereCookies);
    if (uthereCookies.uthereSessionBegin) {
        let sessionTime = currentDate - uthereCookies.uthereSessionBegin;
        let sessionValues = {
            u_session_time: sessionTime,
        }
        setSessionValues(sessionValues, false);
    }
};

/**
 * Atualiza a sessão quando o utilizador reactiva tab
 */
function updateUthereSessionReturn() {
    let isNewSession = false;
    let uthereCookies = getUthereCookies();
    if (uthereCookies.uthereSessionId && uthereCookies.uthereSessionEnd) {
        isNewSession = isNewSessionFunction(uthereCookies.uthereSessionId, uthereCookies.uthereSessionEnd)
    }
    if (isNewSession) {
        let currentDate = new Date().getTime();
        let returnAfter = uthereCookies.uthereSessionEnd ? 0 : currentDate - uthereCookies.uthereSessionEnd;
        uthereCookies.uthereSessionId = generateUid();
        uthereCookies.uthereSessionBegin = currentDate;
        uthereCookies.uthereSessionCount = !uthereCookies.uthereSessionCount ? 1 : (parseInt(uthereCookies.uthereSessionCount) + 1);
        uthereCookies.utherNewUser = 0;
        uthereCookies.uthereSessionEnd = null;
        updateCookies(uthereCookies);
        if (returnAfter > 0) {
            let sessionValues = {
                u_user_return_after: returnAfter,
            }
            setSessionValues(sessionValues, false);
        }
        else {
            setSessionValues(uthereCookies, true);
        }
    }
};


/**
 * Verifica se é uma nova sessão
 * @param {*} uthereSessionId 
 * @param {*} uthereSessionEnd 
 * @returns 
 */
function isNewSessionFunction(uthereSessionId, uthereSessionEnd) {
    let isNewSession = false;
    let currentDate = new Date().getTime();
    if (!uthereSessionId || !uthereSessionEnd) {
        isNewSession = true;
    }
    else if (uthereSessionEnd) {
        let diff = (currentDate - uthereSessionEnd) / 60000;
        if (diff > 30) {
            isNewSession = true;
        }
    }
    return isNewSession;
};

/**
 * Faz set dos session values no analytics
 * @param {*} cookies 
 */
export async function setSessionValues(values, convertFromCookies) {
    try {
        let sessionValues = values;
        if (convertFromCookies) {
            sessionValues = convertCookiesToSessionValues(values);
        }
        sessionValues.u_ab_group = getVwoGroup();
        if (sessionValues && Object.keys(sessionValues).length > 0) {
            import("firebase/analytics").then(async analyticsImp => {
                if (sessionValues.u_guest_user_id) {
                    analyticsImp.setUserId(analyticsImp.getAnalytics(), sessionValues.uthereGuestId);
                }
                analyticsImp.setUserProperties(analyticsImp.getAnalytics(), sessionValues);
            });
        }
    } catch (e) { }
};

/**
 * Coverte objecto de cookies para objecto de valores de sessão
 * @param {*} cookies 
 */
function convertCookiesToSessionValues(cookies) {
    let objectToReturn = {};
    if (cookies) {
        let cookiesKeys = Object.keys(cookies);
        for (let i = 0; i < cookiesKeys.length; i++) {
            let currentReturnKey = mapCookiesSessionVars[cookiesKeys[i]];
            if (currentReturnKey) {
                objectToReturn[currentReturnKey] = cookies[cookiesKeys[i]]
            }
        }
    }
    return objectToReturn;
}
export async function setSessionEntryPoint(entryPoint) {
    try {
        if (entryPoint) {
            let sessionValues = {
                u_entry_point: entryPoint,
            }
            setSessionValues(sessionValues, false);
        }
    } catch (e) { }
}


/******
    * 
    * EVENTS ZONE
    * 
    */
//Regista evento de navegação entre páginas na aplicação
export async function setEventPageNavigation(from, to, landingMethod) {
    try {
        let parameters = {
            u_from: "NA",
            u_to: "NA",
            u_landing_method: "direct",
        }
        if (from) {
            parameters.u_from = from;
        }
        if (to) {
            parameters.u_to = to;
        }
        if (landingMethod) {
            parameters.u_landing_method = landingMethod;
        }
        logUthereEvent('uthere_event_page_navigation', parameters);
    } catch (e) { }
};
/**
 * When CTA is selected
 * @param {*} id 
 * @param {*} scope 
 * @param {*} type 
 * @param {*} branch 
 * @param {*} subranch 
 * @param {*} location 
 */
export async function setEventCTASelected(id, scope, type, branch, subranch, location) {
    ctaTimeStamp = new Date().getTime();
    try {
        let parameters = {
            u_id: id,
            u_scope: scope,
            u_type: type,
            u_page: location,
        }
        if (branch) {
            parameters.u_process_branch = branch;
        }
        if (subranch) {
            parameters.u_process_sub_branch = subranch;
        }
        logUthereEvent('uthere_event_cta_selected', parameters);
    } catch (e) { }
};


//Evento de selecção de notificação
export async function setEventSelectNotification(id, lead, type, branch, subBranch, notificationType) {
    try {
        let parameters = {
            u_id: id,
            u_process_sub_branch: 0,
            u_description: lead,
            u_process_type: type,
            u_process_branch: branch,
            u_type : type,
            u_param_1 : notificationType
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_select_notification', parameters);
    } catch (e) { }
};


//Evento de acesso a simulação cancelada
export async function setEventSimulationCanceled() {
    try {
        let parameters = {
        }
        logUthereEvent('uthere_event_access_simulation_canceled', parameters);
    } catch (e) { }
};


//Evento de pedido de cancelamento de emaul
export async function setEventCancelEmails(emailNumber) {
    try {
        let parameters = {
        }
        if(emailNumber)
        {
            parameters.u_param_1 = emailNumber;
        }
        logUthereEvent('uthere_event_canceled_emails', parameters);
    } catch (e) { }
};

//Evento de scroll
export async function setEventScroll(page, percentage) {
    try {
        let parameters = {
            u_param_1: percentage,
            u_page: page,
        }
        logUthereEvent('uthere_event_scroll_page', parameters);
    } catch (e) { }
};


//Regista evento de início de processo
export async function setEventStartProcess(type, branch, subBranch) {
    try {
        let parameters = {
            u_process_type: type,
            u_process_branch: branch,
            u_process_sub_branch: 0,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_start_process', parameters);

    } catch (e) { }
};

//Regista navegação para um novo passo do processo
export async function setEventProcessStep(type, branch, subBranch, previousStep, nextStep, processStatus, isBack) {
    try {
        let parameters = {
            u_process_type: type,
            u_process_branch: branch,
            u_status: processStatus,
            u_process_sub_branch: 0,
            u_to: nextStep,
            u_from: previousStep,
            u_param_1: isBack,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_go_to_process_step', parameters);
    } catch (e) { }
};

//Regista evento de gerar uma nova proposta
export async function setEventGenerateProposal(branch, subBranch) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_process_sub_branch: 0,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_generate_proposal', parameters);
    } catch (e) { }
};
//Regista evento de consultar uma proposta
export async function setEventSeeProposal(branch, subBranch, numeroDePropostas) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_param_1: numeroDePropostas ? numeroDePropostas : 0,
            u_insurer: 0,
            u_process_sub_branch: subBranch ? subBranch : 0,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_see_proposal', parameters);
    } catch (e) { }
};

export async function setEventSeeProposalFirstTime(branch, subBranch) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_process_sub_branch: subBranch ? subBranch : 0,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_see_proposal_first_time', parameters);
    } catch (e) { }
};


export async function setEventSeeProposalFromEmail(branch, subBranch, emailCount) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_process_sub_branch: subBranch ? subBranch : 0,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        if(emailCount){
            parameters.u_param_1 = emailCount;
        }
        logUthereEvent('uthere_event_see_proposal_from_email', parameters);
    } catch (e) { }
};
//Regista evento de escolher uma proposta
export async function setEventSelectProposal(branch, subBranch, seguradoraEscolhida) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_insurer: seguradoraEscolhida,
            u_process_sub_branch: 0
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_select_proposal', parameters);
    } catch (e) { }
};


//Regista evento de escolher uma proposta
export async function setEventPageLoaded(location, type) {
    try {
        let parameters = {
            u_page: location,
            u_type: type,
        }
        if (ctaTimeStamp) {
            parameters.u_param_1 = new Date().getTime() - ctaTimeStamp;
            ctaTimeStamp = null;
        }
        logUthereEvent('uthere_event_loaded_page', parameters);
    } catch (e) { }
};

//Regista evento de visualização de proposta
export async function setEventProposalError(branch, subBranch, type) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_process_sub_branch: 0,
            u_type: type,
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_proposal_error', parameters);
    } catch (e) { }
};


//Regista evento de finalizar o checkout
export async function setEventFinishCheckout(branch, subBranch, seguradoraEscolhida) {
    try {
        let parameters = {
            u_process_branch: branch,
            u_insurer: seguradoraEscolhida,
            u_process_sub_branch: 0
        }
        if (subBranch) {
            parameters.u_process_sub_branch = subBranch;
        }
        logUthereEvent('uthere_event_finish_checkout', parameters);
    } catch (e) { }
};

async function logUthereEvent(name, parameters) {
    let analyticsImp = await import("firebase/analytics");
    try {
        analyticsImp.logEvent(analyticsImp.getAnalytics(), name, parameters);

        window.fbq(
            'trackCustom', name, parameters
        );
    }
    catch (e) { };

}

