import { doc, collection, onSnapshot } from "firebase/firestore";
import { getDb } from '../../firebase/UthereFirebase';
import { getCurrentProcessId } from "./ProcessLogic";
import { store } from "../../store/store";

let processesListeners = {};
let currentProposals = {};
let currentPercentage = {};

export function getCurrentProposal() {
    let processId = getCurrentProcessId();
    return currentProposals[processId];
}

export function getCurrentPercentage() {
    let processId = getCurrentProcessId();
    return currentPercentage[processId];
}

export function listenToProposal() {
    return new Promise(async function (resolve, reject) {
        try {
            let processId = getCurrentProcessId();
            if (processId) {
                if (!processesListeners[processId]) {
                    let db = await getDb();
                    const rpaProposalsRef = collection(db, "proposals");
                    processesListeners[processId] = onSnapshot(doc(rpaProposalsRef, processId), (doc) => {
                        try {
                            if (doc.data()) {
                                let currentData = getHomeProposalObject(doc.data());
                                if(currentData && currentData.totalPresent && currentData.total){
                                    currentPercentage[processId] = Math.round((currentData.totalPresent / currentData.total) * 100);
                                }
                                else {
                                    currentPercentage[processId] = 0;   
                                }
                                if (currentData.data && currentData.totalPresent === currentData.total) {
                                    currentProposals[processId] = currentData.data;
                                    let payload = {
                                        data: currentData.data,
                                        processId: processId
                                    }
                                    store.dispatch({ type: 'proposal/setProposal', payload: payload });
                                }
                            }
                        } catch (e) { }
                    });
                }
            }
            resolve();
        } catch (e) { resolve() }
    });
};

export function getHomeProposalObject(data) {
    if (data && data.rpaRequest) {
        let bens = data.rpaRequest.bens;
        let paredes = data.rpaRequest.paredes;
        let base = data.rpaRequest.allowBase;
        let configTranquilidade = data.rpaDataTrnquilidade;
        let configAllianz = data.rpaData;

        let totalObjectsPerInsurer = paredes ? 8 : 4;
        let totalPresent = 0;
        let objectToReturn = {
            franquias: [],
            periodoMinimo: 'M',
            bens: bens,
            base: base ? true : false,
            paredes: paredes,
            config: {},
            ok: true,
        };
        let objectToReturnTranquilidade = {
            franquias: [],
            periodoMinimo: 'M',
            bens: bens,
            base: base ? true : false,
            paredes: paredes,
            config: {},
            ok: true,
        };
        try {
            let periodosOk = { A: true, T: true, S: true, M: true };
            if (configTranquilidade && configTranquilidade.prices) {
                let franquiasKeys = Object.keys(configTranquilidade.prices);
                if (franquiasKeys.length < 2) {
                    objectToReturnTranquilidade.franquias = franquiasKeys;
                }
                else {
                    objectToReturnTranquilidade.franquias = ["150", "0"];
                }
                let bensKeys = [];
                let fenomenosKeys = ["fenomenos", "semFenomenos"]
                if (paredes) {
                    bensKeys = ["bens", "semBens"];
                }
                else {
                    bensKeys = ["bens"];
                }
                //Obtem periodos minimos
                for (let i = 0; i < franquiasKeys.length; i++) {
                    let currentFranquia = franquiasKeys[i];
                    objectToReturnTranquilidade.config[currentFranquia] = {};
                    for (let j = 0; j < bensKeys.length; j++) {
                        let currentBens = bensKeys[j];
                        objectToReturnTranquilidade.config[currentFranquia][currentBens] = {};
                        for (let k = 0; k < fenomenosKeys.length; k++) {
                            let currentFenomenos = fenomenosKeys[k];
                            let current = configTranquilidade.prices[franquiasKeys[i]][bensKeys[j]][fenomenosKeys[k]];
                            if (current) {
                                if (current.config) {
                                    objectToReturnTranquilidade.config[currentFranquia][currentBens][currentFenomenos] = current.config;
                                    let periodsKeys = Object.keys(current.config);
                                    for (let i = 0; i < periodsKeys.length; i++) {
                                        let currentPeriod = periodsKeys[i];
                                        let productKey = Object.keys(current.config[currentPeriod])[0];
                                        if (isNaN(current.config[currentPeriod][productKey].precoAnual)) {
                                            current.config[currentPeriod][productKey].precoAnual = null;
                                            periodosOk[currentPeriod] = false;
                                        }
                                    }

                                }
                                else {
                                    break;
                                }
                            }
                            else {
                                break;

                            }
                        }
                    }
                }
                objectToReturnTranquilidade.periodoMinimo = !periodosOk.M && periodosOk.T ? "T" : !periodosOk.T ? "A" : "M";
            }
        } catch (e) {
            objectToReturnTranquilidade.ok = false;
        }

        try {
            if (configAllianz) {
                let franquiasKeys = Object.keys(configAllianz);
                if (franquiasKeys.length < 2) {
                    objectToReturn.franquias = franquiasKeys;
                }
                else {
                    objectToReturn.franquias = ["150", "0"];
                }
                let bensKeys = [];
                let fenomenosKeys = ["fenomenos", "semFenomenos"]
                if (paredes && bens) {
                    bensKeys = ["bens", "semBens"];
                }
                else if (paredes) {
                    bensKeys = ["semBens"];
                }
                else {
                    bensKeys = ["bens"];
                }

                //Obtem periodos minimos
                for (let i = 0; i < franquiasKeys.length; i++) {
                    let currentFranquia = franquiasKeys[i];
                    objectToReturn.config[currentFranquia] = {};
                    for (let j = 0; j < bensKeys.length; j++) {
                        let currentBens = bensKeys[j];
                        objectToReturn.config[currentFranquia][currentBens] = {};
                        for (let k = 0; k < fenomenosKeys.length; k++) {
                            let currentFenomenos = fenomenosKeys[k];
                            let current = configAllianz[franquiasKeys[i]][bensKeys[j]][fenomenosKeys[k]];
                            if (current) {
                                totalPresent += 1;
                                objectToReturn.areaEdificio = current.areaEdificio;
                                objectToReturn.areaAnexos = current.areaAnexos;
                                objectToReturn.bensEdificio = current.bensEdificio;
                                objectToReturn.bensAnexo = current.bensAnexo;
                                objectToReturn.valorEdificio = current.valorEdificio;
                                objectToReturn.valorAnexo = current.valorAnexo;
                                if (current.periodoMinimo === "A") {
                                    objectToReturn.periodoMinimo = "A"
                                }
                                else if (current.periodoMinimo === "T" && objectToReturn.periodoMinimo === "M") {
                                    objectToReturn.periodoMinimo = "T";
                                }
                                else if (current.periodoMinimo === "S" && (objectToReturn.periodoMinimo === "T" || objectToReturn.periodoMinimo === "M")) {
                                    objectToReturn.periodoMinimo = "S";
                                }
                                if (current.config) {

                                    objectToReturn.config[currentFranquia][currentBens][currentFenomenos] = current.config;
                                }
                                else {
                                    break;
                                }
                            }
                            else {
                                break;

                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            return null;
        }
        return {
            data: objectToReturn, total: totalObjectsPerInsurer, totalPresent: totalPresent
        }
    }
}
